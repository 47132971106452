<template>
  <div>
    <a-card :bordered="false" title="入库详情">
      <div slot="extra">
        <a-button ><router-link :to="{ name: '/wms/sale/stock'}">取消</router-link></a-button>
      </div>
      <s-table
        size="default"
        :rowKey="function(record) { return getRandomCode(8) }"
        :columns="stockInColumns"
        :data="stockInData">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
      </s-table>
    </a-card>
    <a-card :bordered="false" title="出库详情">
      <s-table
        size="default"
        :rowKey="function(record) { return getRandomCode(8) }"
        :columns="stockOutColumns"
        :data="stockOutData">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
      </s-table>
    </a-card>
  </div>
</template>

<script>
import { STable } from '@/components'
import { getRandomCode } from '@/utils/util'
import { getStockInDetailInfo, getStockOutDetailInfo } from '@/api/wms/sale'
export default {
  components: {
    STable
  },
  data() {
    return {
      queryParam: {
        ids: this.$route.params.id
      },
      stockInColumns: [
        {
        title: '#',
        width: 50,
        scopedSlots: { customRender: 'serial' }
        },
        {
          title: '入库单号',
          dataIndex: 'purchase_num',
          width: 150
        },
        {
          title: '入库时间',
          dataIndex: 'purchase_date',
          width: 120
        },
        {
          title: '仓库',
          dataIndex: 'warehouse',
          width: 150
        },
        {
          title: '供应商',
          dataIndex: 'supplier_name',
          width: 150
        },
        {
          title: '商品名称',
          dataIndex: 'good_name',
          width: 120
        },
        {
          title: '采购人',
          dataIndex: 'creator_name',
          width: 80
        },
        {
          title: '单位',
          dataIndex: 'good_unit',
          width: 60
        },
        {
          title: '数量',
          dataIndex: 'total_num',
          width: 60
        },
        {
          title: '单价',
          dataIndex: 'unit_price',
          width: 100
        },
        {
          title: '总价',
          dataIndex: 'total',
          width: 100
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 100
        }
      ],
      stockInData: parameter => {
        return getStockInDetailInfo(Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      stockOutColumns: [{
        title: '#',
        width: 50,
        scopedSlots: { customRender: 'serial' }
      },
      {
        title: '出货单号',
        dataIndex: 'sell_num',
        width: 150
      },
      {
        title: '出货时间',
        dataIndex: 'out_date',
        width: 120
      },
      {
        title: '出货人',
        dataIndex: 'creator_name',
        width: 80
      },
      {
        title: '商品名称',
        dataIndex: 'good_name',
        width: 120
      },
      {
        title: '数量',
        dataIndex: 'total_num',
        width: 60
      },
      {
        title: `单价`,
        dataIndex: `unit_price`,
        width: 60
      },
      {
        title: '录入时间',
        dataIndex: 'created_at',
        width: 120
      }],
      stockOutData: parameter => {
        return getStockOutDetailInfo(Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      }
    }
  },
  methods: {
    getRandomCode
  }
}
</script>
